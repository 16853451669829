import { ChildListSchFormType } from "@/types/member/child";
import axios from "axios";

// 자녀 목록 조회
export const loadChildListAPI = async (form: ChildListSchFormType) => {
    return axios.get("/api/ch/child/page-list", { params: { ...form }, });
};

// 물품신청 회원 목록 조회
export const loadCommonChildListAPI = async (form: ChildListSchFormType) => {
    return axios.get("/api/ch/child/common-page-list", { params: { ...form }, });
};